import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ShowCard from "./screens/ShowCard";
import "./App.css";
import NotFound404 from "./screens/NotFound404";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/:id" element={<ShowCard />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
