import React from "react";

function NotFound404() {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen text-white/70">
      <img src="/assets/image/logowhite.png" className=" w-52 h-auto" />
      <h2 className="font-bold text-[18px] mt-4">Üzgünüz... </h2>
      <p className="font-semibold text-[16px] text-center">
        Belirttiğiniz URL'de bir Kart bulunamadı. Mevcut kart düzenlenme
        aşamasında veya kapatılmış olabilir.
      </p>
    </div>
  );
}

export default NotFound404;
