import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa6"; // FontAwesome ikonlarını içe aktar
import { FaEnvelope, FaPhone, FaMapPin } from "react-icons/fa6";
import VCard from "vcard-creator";
import axios from "axios";
import NotFound404 from "./NotFound404";

const Icon = ({ name, ...props }) => {
  const formattedName = name
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("");
  const iconName = `Fa${formattedName}`;
  const IconComponent = FaIcons[iconName];
  return IconComponent ? <IconComponent {...props} /> : null;
};

function ShowCard() {
  const [siteType, setSiteType] = useState("custom");
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [data, setData] = useState(null);

  const openVCard = (vcardData) => {
    const myVCard = new VCard();
    console.log(vcardData);
    myVCard
      .addName(
        vcardData.lastname ? vcardData.lastname : "",
        vcardData.firstname ? vcardData.firstname : ""
      )
      .addCompany(vcardData.companyName ? vcardData.companyName : "")
      .addJobtitle(vcardData.jobTitle ? vcardData.jobTitle : "")
      .addRole(vcardData.role ? vcardData.role : "")
      .addEmail(vcardData.email ? vcardData.email : "")
      .addPhoneNumber(
        vcardData.phoneNumber ? vcardData.phoneNumber : "",
        "HOME"
      )
      .addURL(vcardData.url ? vcardData.url : "")
      .addAddress(vcardData.address ? vcardData.address : "");

    const vcardString = myVCard.toString();

    // VCard verisini Blob'a dönüştür
    const blob = new Blob([vcardString], { type: "text/vcard" });

    // Blob'u indirilecek bir dosya olarak ayarla
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "contact.vcf";
    document.body.appendChild(link);
    link.click();

    // Linki ve URL'yi temizle
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
  };

  const handleSaveClickLog = (clicked) => {
    const description = `Kullanıcı ${clicked.title} butonuna tıkladı.`;
    const logObject = {
      image: clicked.logo,
      title: clicked.title,
      description,
    };

    console.log(logObject);

    axios
      .post(
        `https://api.xtag.com.tr/api/web/card-activity/${data.cardID}`,
        logObject
      )
      .then((res) => {
        if (clicked.action == "redirection") {
          window.location.href = clicked.href;
        } else {
          navigator.clipboard.writeText(clicked.href).then(() => {
            alert("Veri Kopyalandı.");
          });
        }
      });
  };

  useEffect(() => {
    const url = window.location.href;
    const cardID = url.split("/")[3];
    console.log(`geldi ${cardID}`);
    if (cardID) {
      axios
        .get(`https://api.xtag.com.tr/api/web/show-card/${cardID}`)
        .then((res) => {
          if (res.data.error) {
            setEmpty(true);
            setLoading(false);
          } else {
            setData(res.data);
            setSiteType(res.data.siteType);
            if (res.data.siteType == "v-card") {
              setLoading(false);
              openVCard(res.data.vcardData);
            } else if (res.data.siteType == "redirect") {
              window.location.href = res.data.redirection;
            } else {
              setLoading(false);
            }
          }
        });
    } else {
      setLoading(false);
      setEmpty(true);
    }
  }, []);
  return loading ? (
    <></>
  ) : empty ? (
    <NotFound404 />
  ) : (
    <div className=" md:container md:mx-auto">
      <div className=" items-center flex flex-col mt-4">
        <img
          src="/assets/image/logowhite.png"
          alt="logo"
          className="w-[50px] h-[50px] "
        />
      </div>

      {siteType == "custom" ? (
        <>
          <div className="flex justify-center">
            <div className="mt-8 w-[125px] h-[125px] overflow-hidden flex justify-center items-center rounded-full self-center">
              <img
                src={
                  data?.customBlocks?.profilePicture
                    ? data.customBlocks.profilePicture
                    : "/assets/image/logowhite.png"
                }
                className=" min-w-[100%] min-h-[100%] object-cover"
              />
            </div>
          </div>

          <div className="flex flex-col items-center mt-2">
            <h2 className="text-white font-bold text-[21px]">
              {data?.customBlocks?.name}
            </h2>
            <h2 className="text-white/20 font-semibold text-[14px]">
              {data?.customBlocks?.title}
            </h2>
            {data?.customBlocks?.socialMediaList && (
              <div className="flex justify-center items-center gap-x-2 mt-2">
                {data?.customBlocks?.socialMediaList?.map((item, index) => (
                  <a href={item.href} key={index}>
                    <Icon name={item.icon} size={14} color="white" />
                  </a>
                ))}
              </div>
            )}
          </div>

          <div className="mt-4">
            <div>
              {data?.customBlocks?.blocks?.map((item, index) => {
                switch (item.type) {
                  case "preBlock":
                    return (
                      <div key={index} className="mt-8 p-2">
                        {item.customize === "1" ? (
                          <div className="flex justify-center flex-wrap gap-4 items-start">
                            {item.data.map((element, index2) =>
                              element.action == "redirection" ? (
                                <div
                                  onClick={() => {
                                    handleSaveClickLog(element);
                                  }}
                                  key={index2}
                                  className="flex flex-col items-center w-[75px]"
                                >
                                  <img
                                    src={element.logo}
                                    className="w-[75px] h-[75px]  rounded-[20px]"
                                  />
                                  <p className="text-white text-center font-semibold mt-2 text-[10px]">
                                    {element.title}
                                  </p>
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    handleSaveClickLog(element);
                                  }}
                                  key={index2}
                                  className="flex flex-col items-center w-[75px]"
                                >
                                  <img
                                    src={element.logo}
                                    className="w-[75px] h-[75px] bg-white rounded-[20px]"
                                  />
                                  <p className="text-white text-center font-semibold mt-2 text-[10px]">
                                    {element.title}
                                  </p>
                                </button>
                              )
                            )}
                          </div>
                        ) : (
                          <div>
                            {item.data.map((element, index2) => (
                              <div
                                key={index2}
                                className="p-2 bg-white/10 mb-4 flex items-center rounded-lg"
                              >
                                <img
                                  src={element.logo}
                                  alt={element.title}
                                  className="w-11 h-11 rounded-lg mr-4"
                                />
                                <div>
                                  <p className="text-white text-lg font-semibold">
                                    {element.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );

                  case "text":
                    return (
                      <div
                        key={index}
                        className="bg-white/10 m-2 rounded-lg mt-4 p-3"
                      >
                        <p className="text-white text-2xl font-bold">
                          {item.data[0].title}
                        </p>
                        <p className="text-white/60 mt-1">
                          {item.data[0].text}
                        </p>
                      </div>
                    );

                  case "education":
                    return (
                      <div
                        key={index}
                        className="bg-white/10 m-2 rounded-lg mt-4 p-3"
                      >
                        <p className="text-white text-2xl font-bold">
                          Eğitim Durumu
                        </p>
                        {item.data.map((element, index2) => (
                          <div key={index2} className="mt-4">
                            <p className="text-white/70 text-base font-medium">
                              {element.title}{" "}
                              <span className="text-white/30 text-xs">
                                {element.startYear}{" "}
                                {element.endYear ? `• ${element.endYear}` : ""}
                              </span>
                            </p>
                            <p className="text-white/70 text-base font-medium">
                              {element.degree}
                              {element.department
                                ? `, ${element.department}`
                                : ""}
                            </p>
                          </div>
                        ))}
                      </div>
                    );

                  case "job":
                    return (
                      <div
                        key={index}
                        className="bg-white/10 m-2 rounded-lg  mt-4 p-3"
                      >
                        <p className="text-white text-2xl font-bold">
                          İş Deneyimi
                        </p>
                        {item.data.map((element, index2) => (
                          <div key={index2} className="mt-4">
                            <p className="text-white text-lg font-semibold">
                              {element.department}
                            </p>
                            <p className="text-white/70 text-base font-medium">
                              {element.companyName}{" "}
                              <span className="text-white/30 text-xs">
                                {element.startYear}{" "}
                                {element.endYear ? `• ${element.endYear}` : ""}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    );

                  case "certificate":
                    return (
                      <div
                        key={index}
                        className="bg-white/10 m-2 rounded-lg mt-4 p-3"
                      >
                        <p className="text-white text-2xl font-bold">
                          Sertifikalar
                        </p>
                        {item.data.map((element, index2) => (
                          <div key={index2} className="mt-4">
                            <p className="text-white text-lg font-semibold">
                              {element.title}
                            </p>
                            <p className="text-white/70 text-base font-medium">
                              {element.organization}{" "}
                              <span className="text-white/30 text-xs">
                                {element.startYear
                                  ? `• ${element.startYear}`
                                  : ""}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    );

                  case "contact":
                    return (
                      <div
                        key={index}
                        className="bg-white/10 m-2 rounded-lg mt-4 p-3"
                      >
                        <p className="text-white text-2xl font-bold">
                          İletişim
                        </p>
                        {item.data[0]?.mail && (
                          <div className="flex items-center mt-4">
                            <div className="mr-2">
                              <FaEnvelope color="rgba(255,255,255,0.7)" />
                            </div>
                            <p className="text-white/70 text-base font-semibold">
                              {item.data[0].mail}
                            </p>
                          </div>
                        )}
                        {item.data[0]?.phoneNumber && (
                          <div className="flex items-center mt-4">
                            <div className="mr-2">
                              <FaPhone color="rgba(255,255,255,0.7)" />
                            </div>
                            <p className="text-white/70 text-base font-semibold">
                              {item.data[0].phoneNumber}
                            </p>
                          </div>
                        )}
                        {item.data[0]?.address && (
                          <div className="flex items-center mt-4">
                            <div className="mr-2">
                              <FaMapPin color="rgba(255,255,255,0.7)" />
                            </div>
                            <p className="text-white/70 text-base font-semibold">
                              {item.data[0].address}
                            </p>
                          </div>
                        )}
                      </div>
                    );

                  default:
                    return null;
                }
              })}
            </div>
          </div>
        </>
      ) : siteType == "redirect" ? (
        <div className="py-8 flex flex-col items-center">
          <div>
            <h6 className="text-center text-white font-semibold">
              5 Saniye içerisinde yönlendirileceksiniz...
            </h6>
          </div>
        </div>
      ) : (
        <>
          <div className="py-8 flex flex-col items-center">
            <button
              onClick={() => {
                openVCard(data.vcardData);
              }}
              className=" bg-white w-11/12 py-3 rounded-lg font-medium text-[18px]"
            >
              Kişi Kartını Görüntüle
            </button>
          </div>
        </>
      )}

      <div className="my-8 border-t-[1px] border-white/40 py-4 flex flex-col items-center">
        <h2 className="font-medium text-[12px] text-white/40 mt-2">
          Bu kartvizit{" "}
          <a href="https://xtag.com.tr" className="font-bold text-white/60">
            XTAG
          </a>{" "}
          tarafından oluşturulmuştur.
        </h2>
      </div>
    </div>
  );
}

export default ShowCard;
